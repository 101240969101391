import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentFormatter from "../components/content-formatter"

const Preview = ({ serverData }) => (
  <>
    {serverData?.revisions.edges[0].node.content && (
      <Layout>
        <SEO title={serverData?.title} />
        <div className="columns content">
          <ContentFormatter
            content={
              serverData?.revisions.edges[0].node.content
                ? serverData?.revisions.edges[0].node.content
                : ""
            }
          />
        </div>
      </Layout>
    )}
  </>
)

export default Preview

export async function getServerData({ query, headers }) {
  const pageId = query.p || query.preview_id

  const res = await fetch(
    `https://www.taktl-llc.com/.netlify/functions/preview?` +
      new URLSearchParams({
        postId: pageId,
      }),
    { headers }
  )

  const result = await res.json()
  console.log(result);

  return {
    props: result?.response?.node,
  }
}
